import React, { useRef, useState } from 'react'
import './volunteer.css'
import emailjs from 'emailjs-com'

function Volunteer () {
  const form = useRef()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [zip, setZip] = useState('')

  const sendEmail = e => {
    e.preventDefault()
    // jayisback2000@gmail.com
    emailjs
      .sendForm(
        'service_0z2emwt',
        'template_gyzlkbr',
        form.current,
        'user_9pdUXqEldOgwheUjBJFqo'
      )
      .then(
        result => {
          setFirstName('')
          setLastName('')
          setEmail('')
          setZip('')
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <div className='volunteer'>
      <div className='volunteer-container'>
        <div className='title-and-narrative'>
          <h1>Want to volunteer at our food pantry?</h1>
          <p>
            Volunteers are critical to providing food to everyone during these
            difficult times. Pledge to volunteer with our food pantry and help
            us meet the needs of our community.
          </p>
        </div>
        <div className='pledge-to-volunteer'>
          <form ref={form} onSubmit={sendEmail}>
            <input
              type='text'
              value={firstName}
              name='first_name'
              placeholder='First Name'
              onChange={event => setFirstName(event.target.value)}
            />
            <input
              type='text'
              value={lastName}
              name='last_name'
              placeholder='Last Name'
              onChange={event => setLastName(event.target.value)}
            />
            <input
              type='email'
              value={email}
              name='user_email'
              placeholder='Email'
              onChange={event => setEmail(event.target.value)}
            />
            <input
              type='number'
              value={zip}
              name='zip_code'
              placeholder='Enter Zip'
              onChange={event => setZip(event.target.value)}
            />
            <input type='submit' value='PLEDGE TO VOLUNTEER' />
          </form>
        </div>
      </div>
    </div>
  )
}

export default Volunteer
