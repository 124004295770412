import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD8hVOCFTf8O_dknbLIeLvmaCaLk-REx5M",
  authDomain: "nolm-777df.firebaseapp.com",
  projectId: "nolm-777df",
  storageBucket: "nolm-777df.appspot.com",
  messagingSenderId: "306838913827",
  appId: "1:306838913827:web:d60aceae3e3ebf57ac4296",
  databaseURL: "https://nolm-777df-default-rtdb.firebaseio.com"
};
const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);
export const auth = getAuth(app);
