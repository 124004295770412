import React, { useState, useEffect } from "react";
import "./events.css";
import { uid } from "uid";
import { onValue, ref, remove, set, update } from "firebase/database";
import { db, auth } from "../utils/firebase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import { signInWithEmailAndPassword } from "firebase/auth";
import Event from "./Event";
import CloseIcon from "@mui/icons-material/Close";

function Events() {
  const [eventTitle, setEventTitle] = useState("");
  const [events, setEvents] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [eventId, setEventId] = useState("");
  const [date, setDate] = useState(new Date());
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [description, setDescription] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [time, setTime] = useState("00:00");
  const [timeValueToSent, setTimeValueToSent] = useState("");
  const [errorLogin, setErrorLogin] = useState(false);

  var timeValue;

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const result = onValue(ref(db), (snapshot) => {
      setEvents([]);
      const data = snapshot.val();
      if (data !== null) {
        Object.values(data).map((event) =>
          setEvents((oldArray) => [...oldArray, event])
        );
      }
      console.log(events);
    });

    return result;
  }, []);

  const handleEventTitleChange = (e) => {
    setEventTitle(e.target.value);
  };

  const addEvent = () => {
    console.log("test");
    const uuid = uid();
    set(ref(db, `${uuid}/`), {
      uuid,
      eventTitle,
      date:
        monthNames[date.getMonth()] +
        String(` ${date.getDate()}, ${date.getFullYear()}`),
      time: timeValueToSent,
      description,
      complete: false,
    })
      .then(() => console.log("SUCCESSFUL WRITE"))
      .catch((error) => {
        console.log(error.message);
      });

    console.log("TETESTST");

    setEventTitle("");
    setDescription("");
    setDate(new Date());
    setIsAdd(false);
  };

  const handleDeleteEvent = (uuid) => {
    remove(ref(db, `/${uuid}`));
  };

  const handleEdit = (event) => {
    setEventId(event.uuid);
    setEditTitle(event.eventTitle);
    setTempDescription(event.description);
    setIsEdit(true);
    setIsLogin(false);
    setIsAdd(false);
  };

  const handleSubmitEdit = () => {
    update(ref(db, `/${eventId}`), {
      uuid: eventId,
      eventTitle: editTitle,
      date: String(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      ),
      time: timeValueToSent,
      description: tempDescription,
      complete: false,
    });
    setDescription("");
    setIsEdit(false);
  };

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsAdminLoggedIn(true);
        setIsLogin(false);
      })
      .catch((error) => {
        setErrorLogin(true);
        setIsAdminLoggedIn(false);
        console.log(error.message);
      });
  };

  const handleTempDescriptionChange = (e) => {
    setTempDescription(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e);
    var tempTime = time.split(":");
    var hours = Number(tempTime[0]);
    var minutes = Number(tempTime[1]);

    if (hours > 0 && hours <= 12) {
      timeValue = "" + hours;
    } else if (hours > 12) {
      timeValue = "" + (hours - 12);
    } else if (hours === 0) {
      timeValue = "12";
    }

    timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes;
    timeValue += hours >= 12 ? " P.M." : " A.M.";
    setTimeValueToSent(String(timeValue));
  };

  return (
    <div className={`events`}>
      {isLogin && (
        <div className="login-container">
          <h1>NOLM Login</h1>
          <div className="close-button" onClick={() => setIsLogin(false)}>
            <CloseIcon />
          </div>
          <input type="email" onChange={handleEmailChange} value={email} />
          <input
            type="password"
            onChange={handlePasswordChange}
            value={password}
          />
          {errorLogin && (
            <p className="error-login">Please check email and password</p>
          )}
          <button onClick={handleLogin}>Login</button>
        </div>
      )}
      {isAdd && (
        <div className="add-container">
          <div className="close-button" onClick={() => setIsAdd(false)}>
            <CloseIcon />
          </div>
          <input
            type="text"
            placeholder="Title"
            onChange={handleEventTitleChange}
            value={eventTitle}
          />
          <DatePicker
            selected={date}
            onSelect={(date) => {
              console.log(date);
              setDate(date);
            }}
            className="date-picker-event"
          />
          <TimePicker
            onChange={handleTimeChange}
            value={time}
            disableClock
            className="time-picker-event"
          />
          <textarea value={description} onChange={handleDescriptionChange}>
            Enter text here...
          </textarea>
          <button onClick={addEvent} className="add-button">
            Add Event
          </button>
        </div>
      )}
      {isEdit && (
        <div className="edit-container">
          <div className="close-button" onClick={() => setIsEdit(false)}>
            <CloseIcon />
          </div>
          <input
            placeholder="Title"
            type="text"
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
          />
          <DatePicker
            selected={date}
            onSelect={(date) => {
              console.log(date);
              setDate(date);
            }}
            className="date-picker-event"
          />
          <TimePicker
            onChange={handleTimeChange}
            value={time}
            disableClock
            className="time-picker-event"
          />
          <textarea
            value={tempDescription}
            onChange={handleTempDescriptionChange}
          >
            Enter text here...
          </textarea>
          <button onClick={handleSubmitEdit} className="edit-button">
            Change
          </button>
        </div>
      )}
      <div className="events-container">
        <h1>Events</h1>
        <button
          onClick={() => {
            setIsLogin(true);
            setIsAdd(false);
            setIsEdit(false);
          }}
        >
          Admin
        </button>

        {isAdminLoggedIn && (
          <button
            className="add-event-button"
            onClick={() => {
              setIsLogin(false);
              setIsAdd(true);
              setIsEdit(false);
            }}
          >
            Add Event
          </button>
        )}
        {events.map((event, index) => (
          <Event
            title={event.eventTitle}
            time={event.time}
            date={event.date}
            handleDeleteEvent={() => handleDeleteEvent(event.uuid)}
            handleEdit={() => handleEdit(event)}
            description={event.description}
            isAdminLoggedIn={isAdminLoggedIn}
          />
        ))}
      </div>
    </div>
  );
}

export default Events;
