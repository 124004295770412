import React from 'react'
import './give.css'
import QRCode from '../assets/images/qr-code.jpg'

function Donate () {
  return (
    <div className='give'>
      <div className='give-container'>
        <div className='other-payment'>
          <h1>
            Text <span style={{}}>GIVE</span> to 985-615-3104
          </h1>
        </div>
        <h1 className='or-header'>OR</h1>
        <div className='zelle-payment'>
          <p style={{fontSize: 80, fontWeight: 'bold'}}>Zelle</p>
          <p>nolmhousechurch2022@gmail.com</p>
        </div>
        <div className='zelle-payment'>
          <p style={{fontSize: 80, fontWeight: 'bold'}}>Cash App</p>
          <p>$Nolm2023</p>
        </div>
        <div className='payable-to'>
          <h1 className='payable-to-title'>Make donations payable to:</h1>
          <h1 className='payable-to-description'>
            Newness of Life Ministries <br />
            P.O. Box 40892 <br />
            Baton Rouge, La. 70835
          </h1>
        </div>
        <h1 className='or-header'>OR</h1>
        <div className='online-giving'>
          <p>Donate Online</p>
          <p>Scan the QR Code</p>
          <img src={QRCode} alt='QRCode' />
          <a
            rel='stylesheet'
            href='https://newnessoflifeministries.churchtrac.com'
          >
            {' '}
            Or Click Here{' '}
          </a>
        </div>
        <div className='thankyou-message'>
          <h1>Thank you! Your giving is changing the world.</h1>
          <p>
            You can support the work Newness of Life Ministries is doing in your
            community and around the world.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Donate
